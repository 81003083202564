import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSortBy, useTable, useColumnOrder, useExpanded} from "react-table";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NoData from "../NoData";
import {areEqualProps} from "../../actions/commonActions";

const ReactTable = (props) => {
    let {
        className,
        columns,
        data,
        headerClassName,
        bodyClassName,
        dependencyArray = [],
        manualSortBy = false,
        onTableChange = () => {
        },
        columnOrder = [],
        hiddenColumns = [],
        emptyDataText,
        emptyTextStyle = {},
        expandable = false,
        defaultSort = []
    } = props
    columns = columns.filter(el => !el.hidden)
    const initialState = {
        columnOrder,
        hiddenColumns
    }
    if (defaultSort?.length) {
        const sortData = [];
        defaultSort?.map((item) => {
            sortData.push({
                id: item,
                desc: true
            });
        });
        initialState.sortBy = sortData
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: {sortBy, expanded},
    } = useTable(
        {
            columns: React.useMemo(() => columns, dependencyArray),
            data: React.useMemo(() => data, dependencyArray),
            manualSortBy,
            initialState
        },
        useSortBy,
        useColumnOrder,
        useExpanded,
    )

    useEffect(() => {
        onTableChange({
            sortBy,
            expanded,
        })
    }, [sortBy])
    return (
        <>
            <table className={className} {...getTableProps()}>
                <thead className={headerClassName}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th 
                                    className={column.className} 
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{ ...column.style }}
                                >
                                    <div className="flex items-center gap-1">
                                        <div>
                                            {column.render('Header')}
                                        </div>
                                        {/* Add a sort direction indicator */}
                                        <div>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <KeyboardArrowDownIcon fontSize={"small"}/>
                                                    : <KeyboardArrowUpIcon fontSize={"small"}/>
                                                : column.canSort &&
                                                <>
                                                    <KeyboardArrowUpIcon className={"!block !h-4 !w-4"}/>
                                                    <KeyboardArrowDownIcon className={"!block -mt-2.5 !h-4 !w-4"}/>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody className={bodyClassName} {...getTableBodyProps()}>
                {rows.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                })}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            {rows.length === 0 && <NoData text={emptyDataText} style={emptyTextStyle}/>}
        </>
    );
};

ReactTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    dependencyArray: PropTypes.array,
    manualSortBy: PropTypes.bool,
    onTableChange: PropTypes.func,
    emptyTextStyle: PropTypes.any
    
};

export default memo(ReactTable, areEqualProps);
