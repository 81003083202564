import Storage from "../utils/Storage";

export const grantAccess = (token, user) => {
    const search = window.location.search;
    const params = new URLSearchParams(search)
    const redirect_uri = params?.get("redirect_uri");
    Storage.setEasm(user?.easm_enabled);
    Storage.setGenpt(user?.genpt_enabled);
    Storage.setToken(token)
    if (redirect_uri && validateURL(redirect_uri)) {
        window.location = redirect_uri
    } else if (user.is_superadmin)
        window.location = "/admin"
    else
        window.location = "/profile"
}

function validateURL(sUrl) {
    const url = new URL(sUrl);
    const urlHostname = url.hostname.trim();

    if (urlHostname === '') {
        return true;
    } else {
        return urlHostname.toUpperCase() === window.location.hostname.trim().toUpperCase();
    }
}

export const logout = () => {
    Storage.clear()
    //clear session storage
    Storage.clear(true)
    window.location = "/"
}
