import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from "../../../../components/Button";
import { exploreDashboard, get2DigitFormattedNumber, getAllDataFromPages } from '../../../../actions/commonActions';
import MainLoader from "../../../../components/Loader/MainLoader";
import { useInView } from "react-intersection-observer";
import { useListVulnerabilityAssessment } from '../../../../hooks/dashboard';
import AllTableData from '../../../../components/Dashboard/AllTableData';
import CloudIcon from '../../../../components/SvgIcons/CloudIcon';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MonitorIcon from '@mui/icons-material/Monitor';
import ApiIcon from '../../../../components/SvgIcons/ApiIcon';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { AlertContext } from '../../../../components/SuccessOrFailedModal';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import EXPLORE_CONSTANTS from "../../../../containers/Explore/utils/EXPLORE_CONSTANTS";
import { LayoutContext } from '../../../../Providers/LayoutProvider';
import moment from "moment";
import { useUserRole, useValidateLogin } from '../../../../hooks/auth';
import Status from './Status';
import { SIEMBA_ADMIN } from '../../../../utils/roles';
import { assetCriticalityMapping } from '../../../../utils/constants/asset';
import { setSubscribeModal } from '../../../../redux/reducers/dashboard';

const VAList = ({ filterTitle }) => {
    const dispatch = useDispatch()

    const [tableState, setTableState] = useState(null)
    const [selectedRows, setSelectedRows] = useState([]);
    const [departmentsList, updateDepartmentsList] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [companyId, updateCompanyId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState(null);
    const { data: userData } = useValidateLogin();
    
    const userType = userData?.user?.user_type;


    const navigate = useNavigate();
    const role = useUserRole();
    const { mainUrl } = useContext(LayoutContext);
    const explore = (props) => {
        exploreDashboard(EXPLORE_CONSTANTS.VULNERABILITY_ASSESSMENT, props.id, props, () => {
            navigate(`${mainUrl}/va/`)
        })
    }

    const rowReset = () => {
        setSelectedRows([])
    }

    const {
        data: scanSummaryData,
        isFetching: isFetchingUser,
        fetchNextPage: fetchNextUser,
        hasNextPage: hasNextUser
    } = useListVulnerabilityAssessment({
        search,
        companyId: userData?.user?.currentCompanyId,
        sortBy: tableState?.sortBy,
        status: filter && filter?.Status ? filter.Status : null,
        company: filter && filter?.Customer ? filter.Customer : null,
        assetCriticality: filter && filter["Asset Criticality"] ? filter["Asset Criticality"] : null,
        startDate: filter && filter?.date_range ? moment(filter?.date_range?.startDate).utcOffset(0).startOf('day').toISOString() : null, 
        endDate: filter && filter?.date_range ? moment(filter?.date_range?.endDate).utcOffset(0).endOf('day').toISOString() : null
    })

    const { ref: firstTabRef, inView: inViewFirstTab } = useInView()

    useEffect(() => {
        fetchNextUser?.()
    }, [inViewFirstTab])
    
    const totalScanCount = scanSummaryData?.pages[0]?.totalScanCount;
    const companyCount = scanSummaryData?.pages[0]?.companyCount;
    const companiesData = scanSummaryData?.pages[0]?.companiesData;
    const isVASubscribed = userData?.user?.va_subscribed;

    let scanDetails = getAllDataFromPages(scanSummaryData?.pages, "scanSummary") || []; 
    if (!isFetchingUser && !isVASubscribed) {
        dispatch(setSubscribeModal({
            open: true,
        }))
    }
    const onTableChange = (tableState) => {
        setTableState(tableState)
    }

    const handleScanDurationFormat = (duration) => {
        const seconds = Number(duration);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
        return dDisplay + h + ":" + m + ":" + s;
    }

    let columns = [
        {
            Header: 'Asset',
            accessor: 'assetName',
            Cell: props => {
                const itemDetails = props?.value
                return (
                    <Link 
                        className={"link"} 
                        to={`${mainUrl}/all_va/${itemDetails?.platformAssetId}/dashboard/`}
                    >
                        {itemDetails?.assetName}
                    </Link>
                )
            },
        },
        {
            Header: 'Asset Criticality',
            accessor: 'priority',
            disableSortBy: true,
        },
        {
            Header: 'Total Confirmed Findings',
            accessor: 'totalFindings',
            disableSortBy: true,
            Cell: props => {
                const item = props?.value;
                const totalConfirmedFindings = Number(item?.confirmedTotalIssues || 0);
                return (
                    <Link 
                        className={"link"} 
                        to={`${mainUrl}/all_va/${item?.platformAssetId}/dashboard/?status=open&type=confirmed`}
                    >
                        <span>{totalConfirmedFindings}</span>
                    </Link>
                )
            },
        },
        {
            Header: 'Scan Title',
            disableSortBy: true,
            accessor: 'scanTitle',
            Cell: props => {
                const scanTitle = props?.value
                return (
                    <span className='scan-title'>{scanTitle}</span>
                )
            },
        },
        {
            Header: 'Scan Date',
            disableSortBy: true,
            accessor: 'scanStartDateTime',
            Cell: props => {
                const item = props?.value
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div"> {moment(item?.scanStartDatetime).format("MM/DD/YYYY")}</span>
                       
                    </div>
                )
            },
        },
        {
            Header: 'Total IPs',
            disableSortBy: true,
            accessor: 'totalIps',
        },
        {
            Header: 'Status',
            disableSortBy: true,
            accessor: 'status',
            Cell: props => {
                const status = props?.value;
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div">{status.charAt(0).toUpperCase() + status.slice(1)}</span>      
                    </div>
                )
            }
        },
        {
            Header: 'Duration',
            disableSortBy: true,
            accessor: 'durationSecs',
            Cell: props => {
                const durationSecs = props?.value
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div"> {handleScanDurationFormat(durationSecs)}</span>
                       
                    </div>
                )
        },
            
        },
        {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            Cell: props => {
                let info = props?.value;
                return (
                    <>
                        <Button
                            onClick={() => explore(info)}
                            variant={"text"} className={"!text-headings"}
                            rightArrow={true}
                        >
                            Explore
                        </Button>
                    </>
                )
            },
            className: 'w-[130px]'
        },
    ];
    if(userType === 'provider'){
        columns.splice(1, 0, {
            Header: 'Customer',
            disableSortBy: true,
            accessor: 'customerName',
        },)
    }

    let tableData = scanDetails?.map((item, index) => {
        return ({
            id: item?.id,
            index: get2DigitFormattedNumber(index + 1),
            scanTitle: item?.scanTitle,
            assetName: item,
            priority: assetCriticalityMapping[item?.assetCriticality],
            totalFindings: item,
            scanStartDateTime: item,
            totalIps: item?.totalIps,
            status: item?.status,
            durationSecs: item?.durationSecs,
            actions: item,
            customerName: item?.customerName
        })
    })

    let hiddenColumns = []

    const companyList = [];
    if (companiesData) {
        companiesData.map((company) => {
            companyList.push({
                id: company?.id,
                name: company?.company_name,
            });
        });
    }


    const filterOptionsAdmin = [
        {
            name: "Customer",
            type: "dropdown",
            filterValues: companyList, //companyNames,
            firstFilter: true,
            multiSelector: true,
        },
        {
            name: "Status",
            type: "option",
            filterValues: ["Submitted", "Queued", "Running", "Paused", "Finished", "Canceled", "Error", "Loading"],
            multiSelector: true,
            secondFilter: true,
        },
        {
            name: "Asset Criticality",
            type: "option",
            filterValues: ["Mission Critical", "Business Critical", "Significant Impact", "Limited Impact", "Negligible Impact"],
            multiSelector: true,
            secondFilter: false,
        },
        {
            name: "date range",
            type: "date",
            lastFilter: true,
        }
    ];
    const filterOptionsCustomer = [
        {
            name: "Status",
            type: "option",
            filterValues: ["Submitted", "Queued", "Running", "Paused", "Finished", "Canceled", "Error", "Loading"],
            multiSelector: true,
            firstFilter: true,
        },
    ]
    return (
        <div>
            <Status count={totalScanCount} companyCount={companyCount}/>
            <div className="asset-list">
                {isFetchingUser && <MainLoader />}
                <AllTableData
                    title={"All Vulnerability Assessments"}
                    searchText={"Search for any VAs, keywords etc..."}
                    description={"See the list of all your Vulnerability Assessments below"}
                    columns={columns}
                    tableData={tableData}
                    dependencyArray={[scanDetails, selectedRows]}
                    onTableChange={onTableChange}
                    onSearch={value => setSearch(value)}
                    tableClassName={"lg:!max-h-[calc(100vh-240px)]"}
                    filterTitle={filterTitle}
                    moreRef={firstTabRef}
                    hasNextPage={hasNextUser}
                    hiddenColumns={hiddenColumns}
                    filterEnabled={true}
                    filterOptions={role === SIEMBA_ADMIN ? filterOptionsAdmin : filterOptionsCustomer}
                    setFilter={setFilter}
                    nullifyDateFilter={true}
                />
            </div>
        </div>
        
    );
};

export default VAList;