

import React from 'react';

const VAIconGrey = ({ width, height, ...props }) => (
<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.4454 3.8689C20.4517 17.2989 21.9026 14.2497 12.1965 21C9.66405 19.2397 7.89342 18.1458 6.65625 17.2512L20.4454 3.8689Z" fill="#666"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.4553 0L19.5294 2.13805L5.74851 15.512C3.44662 13.4654 4.07973 11.7322 4.07973 2.8687L8.2063 0L16.4553 0Z" fill="#666"/>
</svg>
);

export default VAIconGrey;

