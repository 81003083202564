import React, {memo} from 'react';

const MyCommunityIcon = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className={"inline-block"}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.94915 8.29504C6.33052 7.68484 6.33052 6.99836 6.02542 6.38816C5.87287 6.00679 5.56777 5.70169 5.1864 5.54914C4.95757 5.39659 4.72875 5.32031 4.42365 5.32031H4.11855C3.43207 5.32031 2.74559 5.70169 2.36422 6.31189C1.90657 7.07464 2.05912 7.91366 2.51677 8.60013C2.66932 8.82896 2.89814 8.98151 3.12697 9.13406C3.43207 9.28661 3.81345 9.43916 4.11855 9.43916H4.2711C5.03385 9.21033 5.64405 8.90523 5.94915 8.29504Z"
                fill="white"/>
            <path
                d="M6.33086 17.5243C5.94948 17.1429 5.33928 17.1429 4.95791 17.5243C4.57653 17.9057 4.57653 18.5159 4.95791 18.8972C6.10203 19.9651 7.39871 20.8041 8.84793 21.338V20.8803C8.84793 20.3464 9.00048 19.8888 9.2293 19.4311C8.16145 18.9735 7.16988 18.3633 6.33086 17.5243Z"
                fill="white"/>
            <path
                d="M17.7735 17.5229C16.9344 18.3619 15.9429 18.9721 14.875 19.3535C15.1038 19.8112 15.2564 20.2688 15.2564 20.8027C15.2564 20.9553 15.2564 21.1078 15.1801 21.2604C16.7056 20.8027 18.0023 19.9637 19.1464 18.8196C19.5278 18.4382 19.5278 17.828 19.1464 17.4466C18.7651 17.1415 18.1548 17.1415 17.7735 17.5229Z"
                fill="white"/>
            <path
                d="M4.04323 10.4287C3.5093 10.4287 3.05166 10.2761 2.59401 9.97101C2.44146 9.89474 2.36518 9.81846 2.21263 9.74219C1.90754 11.2677 1.90754 12.8694 2.36518 14.3949C2.44146 14.8526 2.89911 15.0814 3.28048 15.0814H3.5093C4.04323 14.9288 4.34832 14.3949 4.19577 13.9373C3.89068 12.7169 3.89068 11.5728 4.04323 10.4287Z"
                fill="white"/>
            <path
                d="M10.6027 2.80204C10.4502 2.26811 9.91624 1.96301 9.45859 2.11556C7.93309 2.49694 6.56014 3.25968 5.41602 4.32753C5.56857 4.40381 5.72112 4.48008 5.79739 4.55635C6.25504 4.78518 6.63642 5.16655 6.86524 5.6242C7.78054 4.86145 8.77212 4.32753 9.91624 4.02243C10.4502 3.79361 10.7553 3.33596 10.6027 2.80204Z"
                fill="white"/>
            <path
                d="M18.3055 4.48006C18.4581 4.40379 18.6106 4.32751 18.6869 4.25124C17.5428 3.18339 16.1698 2.49692 14.6443 2.03927C14.1104 1.88672 13.6528 2.19182 13.5002 2.72574C13.3477 3.25966 13.6528 3.71731 14.1867 3.86986C15.3308 4.17496 16.3224 4.70888 17.2377 5.47163C17.4665 5.16653 17.8479 4.78516 18.3055 4.48006Z"
                fill="white"/>
            <path
                d="M21.8154 9.74219C21.6628 9.81846 21.5865 9.89474 21.434 10.0473C20.9763 10.2761 20.5187 10.4287 19.9848 10.5049C20.2136 11.649 20.1373 12.8694 19.8322 13.9373C19.6797 14.4712 19.9848 14.9288 20.5187 15.0814H20.7475C21.1289 15.0814 21.5865 14.7763 21.6628 14.3949C22.1205 12.8694 22.1967 11.2677 21.8154 9.74219Z"
                fill="white"/>
            <path
                d="M20.8995 9.05778C21.1284 8.90523 21.3572 8.75268 21.5097 8.52386C21.9674 7.91366 22.0437 6.99836 21.6623 6.31189C21.2809 5.62541 20.5945 5.32031 19.908 5.32031H19.6792C19.4503 5.32031 19.1452 5.47286 18.9164 5.54914C18.535 5.77796 18.3062 6.08306 18.1537 6.38816C17.8486 6.99836 17.8486 7.68484 18.23 8.29504C18.6113 8.90523 19.2215 9.28661 19.908 9.28661H19.9843C20.2131 9.28661 20.5945 9.21033 20.8995 9.05778Z"
                fill="white"/>
            <path
                d="M12.0515 18.8203C11.365 18.8203 10.6786 19.2017 10.3735 19.7356C10.1446 20.0407 10.0684 20.4221 10.0684 20.8797C10.0684 21.1848 10.1446 21.4137 10.2209 21.6425C10.526 22.4052 11.2888 22.8629 12.1278 22.8629C12.9668 22.8629 13.6533 22.329 14.0347 21.6425C14.1109 21.4137 14.1872 21.1086 14.1872 20.8797C14.1872 20.4984 14.0347 20.117 13.8821 19.7356C13.3482 19.1254 12.738 18.8203 12.0515 18.8203Z"
                fill="white"/>
        </svg>
    );
};

export default memo(MyCommunityIcon);
