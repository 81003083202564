

import React from 'react';

const EASMIconTheme = ({ width, height, ...props }) => (
<svg width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M6.36996 18.65L19.68 8.82V7.5C19.6783 6.84732 19.4889 6.20889 19.1343 5.66096C18.7796 5.11303 18.2747 4.67876 17.68 4.41L13.1 2.31C12.6557 2.10741 12.1732 2.00256 11.685 2.00256C11.1967 2.00256 10.7142 2.10741 10.27 2.31L5.67996 4.41C5.08441 4.67774 4.57886 5.11177 4.22406 5.65993C3.86927 6.20808 3.68033 6.84704 3.67996 7.5V14.85C3.67792 15.353 3.78818 15.8502 4.0027 16.3052C4.21722 16.7601 4.53059 17.1615 4.91996 17.48L6.36996 18.65Z" fill="#12AFB899"/>
<path d="M7.52002 19.58L9.52002 21.23C10.1254 21.729 10.8855 22.0019 11.67 22.0019C12.4546 22.0019 13.2147 21.729 13.82 21.23L18.41 17.48C18.8007 17.162 19.1156 16.761 19.3318 16.306C19.548 15.8511 19.6601 15.3537 19.66 14.85V10.6L7.52002 19.58Z" fill="#12AFB8"/>
</svg>

);

export default EASMIconTheme;

